<template>
  <div class="message-wrap">
    <div class="pers-message-body">
      This browser is outdated and will be deactivated in:
      <Countdown :days="16" />Please Install a new Version.<br /><a
        class="pers-link"
        href="https://downloads.crccloud.us/"
        >Download Here</a
      >
    </div>
  </div>
</template>

<script>
import Countdown from "./Countdown.vue";

export default {
  name: "PersistentMessage",
  components: {
    Countdown,
  },
  props: {
    messageText: {
      type: String,
      default: "Local",
    },
    backgroundColor: {
      type: String,
      default: "#000",
    },
  },
  data() {
    return {
      //message: this.messageText
    };
  },
  created() {},
  methods: {},
  computed: {
    message() {
      return this.messageText;
    },
  },
};
</script>

<style scoped>
.message-wrap {
  color: #fff;
  text-align: center;
  padding: 1rem;
  background-color: #970000;
  min-width: 180px;
  width: 100%;
}
.pers-message-body {
  font-size: 1rem;
}
.pers-link {
  color: #fff;
  font-weight: bold;
  font-size: 1.2rem;
}
.pers-link:hover {
  color: #fff;
  text-decoration: underline;
}
</style>
