<template>
    <div>{{ days }} days and {{ hours }} : {{ minutes }} : {{ seconds }}</div>
</template>

<script>
export default {
  name: 'Countdown',
  data() {
    return {
      countdownDate: new Date(new Date().getFullYear(), 8, 25).getTime(), // August 1 of the current year
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      countdownTimer: null
    }
  },
  methods: {
    startCountdown() {
      this.countdownTimer = setInterval(() => {
        const now = new Date().getTime();
        const distance = this.countdownDate - now;

        if (distance < 0) {
          // If it's past August 1 of this year, set for next year
          // this.countdownDate = new Date(new Date().getFullYear() + 1, 7, 1).getTime();
          //september 25
          this.countdownDate = new Date(new Date().getFullYear(), 8, 25).getTime();
        }

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    }
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  }
}
</script>

<style scoped>
.countdown {
  display: flex;
  justify-content: space-around;
}
</style>