export default{
    namespaced: true,
    state: {
      //general modals
      filterDrivers: [],
    },
    getters: {
    },
    mutations: {
      setData(state, payload) {
        console.log("got data: ", payload);
        state[payload.type] = [...payload.data];
      },
      resetData(state, payload) {
        console.log("reseting data: ", payload);
        state[payload.type] = [];
      },
    },
    actions: {
    },
    modules: {
    }
  }


  