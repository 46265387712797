export default{
  namespaced: true,
  state: {
    user: {
      isLogged: false,
      username: null,
      email: null,
      status: null,
      id: null,
    },
  },
  getters: {
    isUserEmail: (state) => {
      return state.user.email;
    }
  },
  mutations: {
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.username = payload.username;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
      state.user.depID = null;
      //local
      localStorage.crcToken = payload.jwt;
      localStorage.crcEmail = payload.email;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.username = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
      state.user.depID = null;
      //local
      localStorage.removeItem("crcToken");
      localStorage.removeItem("crcEmail");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.crcToken;
      state.user.username = payload.username;
      state.user.email = payload.email;
      state.user.status = payload.status;
      state.user.id = payload.id;
      state.user.depID = payload.depID;
    },
    setStatus(state, payload) {
      localStorage.removeItem("crcToken");
      localStorage.crcToken = payload.jwt;
      state.user.token = payload.jwt
      state.user.status = payload.status;
    },
    clearUserData(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.username = null;
      state.user.email = null;
      state.user.status = null;
      state.user.id = null;
      state.user.depID = null;
    },
  },
  actions: {
    loginUser({commit,dispatch}, payload) {
      commit("loginUser", payload);
      //dispatch("favorites/loadFavorites","", { root: true });;
    },
    logoutUser({commit}) {
      commit("logoutUser");
      //commit('layouts/dropAllData', null, { root: true });
    },
  },
  modules: {
  }
}
