<template>
  <section class="hero is-fullheight loginbg">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="center-grid">
      <div>
        <div class="grid-center">
          <div class="logo-container">
              <div class="has-text-centered is-size-3 has-text-light mb-4">
                <div class="has-text-centered mb-2">
                  <img
                    src="~@/assets/crc_logo_light.svg"
                    alt="CRC MD Office"
                    width="100%"
                  />
                </div>
                <!-- <div class="sx-title">ADMIN<br/>SYSTEM</div> -->
                <div class="sx-subtitle is-bebas">CRC MD Office</div>
              </div>
        </div>
        <div class="forms-container">
              <div
                class="box animated mt-2"
                v-bind:class="{
                  shake: form.error,
                  intensifies: form.explode,
                  red: form.explode,
                }"
                @keyup.enter.exact="onPressEnter"
              >
                <b-field
                  label="Email"
                  :type="form.email.status"
                  :message="form.email.message"
                >
                  <b-input
                    icon="envelope"
                    type="email"
                    v-model="form.email.data"
                    placeholder="Your email"
                    required
                  ></b-input>
                </b-field>
                <b-field
                  label="Password"
                  :type="form.pass.status"
                  :message="form.pass.message"
                >
                  <b-input
                    icon="lock-keyhole"
                    type="password"
                    v-model="form.pass.data"
                    placeholder="Your password"
                    required
                  ></b-input>
                </b-field>
                <a @click="showModal('restore')" type="is-light">Forgot password?</a>

                <b-message :type="form.status" v-if="form.result">
                  {{ form.message }}
                </b-message>

                <div class="field has-text-centered mt-5">
                  <button class="button is-primary" @click="onSubmit()">
                    LOGIN
                  </button>
                  <b-button type="is-text" @click="showModal('register')">
                    Register?
                  </b-button>
                </div>
              </div>
          </div></div>

          
          <div class="has-text-centered mt-6">
                <small class="has-text-sxlight"
                  >{{ currentYear }} &copy;  FoxSolutions SRL. All Rights Reserved.
                </small
                >
          </div>
        </div>
    </div>
  </section>
</template>

<script>
import toast from '@/mixins/toast';
import client from '@/mixins/client';

export default {
  name: "LoginView",
  mixins: [toast, client],
  data() {
    return {
      form: {
        email: {
          data: "",
          status: "",
          message: "",
        },
        pass: {
          data: "",
          status: "",
          message: "",
        },
        visible: true,
        message: "",
        result: false,
        status: "",
      },
      isLoading: false,
      currentYear: new Date().getFullYear(),
    };
  },
  beforeMount() {
    document.body.className = document.body.className.replace(
      "has-navbar-fixed-top",
      ""
    );
  },
  mounted() {
    if (localStorage.crcEmail) {
      this.form.email.data = localStorage.crcEmail;
    }
    if(this.$store.state.users.logged){
      this.$router.push({ path: "/dashboard" }).catch((err) => {
        console.log(err);
      });
    }
  },
  methods: {
    onPressEnter() {
      //if current root is login
      if (this.$route.name === "login") this.onSubmit();
    },
    showModal(e) {
      this.$store.commit("modals/showModal", e);
    },
    onSubmit() {
      console.log("submit");
      if (this.emailCheck("email") & this.passCheck("pass")) {
        //this.form.visible = false;
        localStorage.crcEmail = this.form.email.data;
        let clientID = this.checlClient();
        if (clientID) {
          this.$store.commit("setClientID", clientID);
        }else{
          return;
        }
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/users/login.php`, {
            email: this.form.email.data,
            pass: this.form.pass.data,
            clientID: this.$store.state.clientID,
          })
          .then((response) => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.toast("Logged in successfully", "is-success");
              this.form.status = "is-success";
              this.$store.commit("users/loginUser", response.data);
              //!!!redirect
              this.$router.push({ path: "/dashboard" }).catch((err) => {
                console.log(err);
              });
            } else {
              this.toast("Error logging in", "is-danger");
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.pass.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email asxress";
        return false;
      } else {
        this.form.email.status = "is-success";
        return true;
      }
    },
    passCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "Please enter a Password";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    hasError() {
      if (this.form.countErrors > 2) {
        this.hasMultipleErrors();
        this.form.countErrors = 0;
      } else {
        this.form.error = true;
        setTimeout(() => (this.form.error = false), 1500);
      }
      setTimeout(() => (this.form.submited = false), 1500);
    },
    hasMultipleErrors() {
      this.form.explode = true;
      setTimeout(() => (this.form.explode = false), 2000);
    },
  },
  metaInfo: {
    title: 'Login | CRC MD Office',
    htmlAttrs: {
      lang: 'en'
    }
  },
};
</script>
<style scoped>
@import "~@/static/intensifies.css";
.red {
  background: #e40000;
  transition: background 2s;
}
.body.has-navbar-fixed-top,
html.has-navbar-fixed-top {
  pasxing-top: 0 !important;
}


.center-grid{
  display: grid;
  place-items: center;
  height: 100vh;
}

.forms-container{
  width: 360px;
  align-items: center;
}

.grid-center{
  display:flex;
  align-items: center;
  flex-direction: column;
}

.sx-subtitle{
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 2rem;
  color: #97a3ae;
}

.sx-title{
  line-height: 0.8;
  font-size: 4.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #fff;
}
</style>
