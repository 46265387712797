<template>
  <section>
    <b-modal :active.sync="isActive" has-modal-card trap-focus aria-role="dialog" aria-modal :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Register</p>
          <button type="button" class="delete" @click="closeModal()" />
        </header>
        <section class="modal-card-body">
          <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>
          <form autocomplete="off" v-if="form.visible">
          <b-field  label="Name" :type="form.name.status" :message="form.name.message">
            <b-input type="name" v-model="form.name.data" placeholder="Your Name"></b-input>
          </b-field>
          <b-field  label="Email" :type="form.email.status" :message="form.email.message">
            <b-input type="email" v-model="form.email.data" placeholder="Your Email"></b-input>
          </b-field>
          <b-field label="Phone" :type="form.phone.status" :message="form.phone.message">
            <p class="control">
            <b-select v-model="form.phone.code">
                <option value="+373">+373</option>
                <option value="+1">+1</option>
            </b-select>
            </p>
            <b-input type="text" v-model="form.phone.data" placeholder="Your Phone" expanded></b-input>
          </b-field>
          <!-- <b-field class="m-t-md" :label="this.form.human.label" :type="form.human.status"
            :message="form.human.message" horizontal>
            <b-input type="text" v-model="form.human.data" placeholder="Your answer" required></b-input>
          </b-field> -->
        </form>
          <b-message class="mt-2" :type="form.status" v-if="form.result">
            {{ form.message }}
          </b-message>
          <div v-if="!form.visible" class="has-text-centered">
            <b-button type="is-primary" @click="closeModal()">
              Close
            </b-button>
          </div>
        </section>
        <footer class="modal-card-foot" v-if="form.visible">
          <b-button @click="closeModal()">
            Cancel
          </b-button>
          <b-button type="is-primary" @click="startRegister()" :loading="isLoading">
            Register
          </b-button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import formCheck from "@/mixins/formCheck";

export default {
  name: "RegisterModal",
  mixins: [formCheck],
  data() {
    return {
      isLoading: false,
      countErrors: 0,
      form: {
        name: {
          data: "",
          status: "",
          message: ""
        },
        email: {
          data: "",
          status: "",
          message: ""
        },
        phone: {
          code: "+373",
          data: "",
          status: "",
          message: ""
        },
        accountType: {
          data: "0",
          status: "",
          message: ""
        },
        human: {
          lable: "",
          summ: null,
          data: null,
          status: "",
          message: ""
        },
        visible: true,
        message: "",
        result: false,
        status: ""
      }
    };
  },
  methods: {
    capitalizeWords(string) {
      let arr = string.split(" ");
      return arr.map(element => {
        return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
      }).join(" ");
    },
    generateCapcha() {
      let var0 = Math.floor(Math.random() * 9) + 1;
      let var1 = Math.floor(Math.random() * 9) + 1;
      this.form.human.summ = var0 + var1;
      this.form.human.label = var0 + '+' + var1 + '=';
    },

    startRegister() {
      this.isLoading = true;
      if (this.formCheck("name") & this.emailCheck() & this.formCheck("phone")){     
        
        Promise.all([this.checkEmail(), this.checkPhone()]).then((results) => {
        if (results[0] && results[1]) {
          this.doRegister();
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
        console.log('An error occurred');
      });

      }else{
        this.isLoading = false;
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },

    checkEmail() {
      return new Promise((resolve, reject) => {
        if (this.formCheck("email")) {
          this.axios
            .post("libs/check_avalible.php", {
              email: this.form.email.data,
              action: "checkEmail",
              token: this.$store.state.tempToken,
            })
            .then(response => {
              this.isLoading = false;
              console.log("checkEmail:", response.data);
              if (response.data.success) {
                resolve(true);
              } else {
                this.form.email.message = response.data.message;
                this.form.email.status = "is-danger";
                resolve(false);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              reject(false);
            });
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          resolve(false);
        }
      });
    },

    checkPhone() {
      return new Promise((resolve, reject) => {
        if (this.formCheck("phone")) {
          this.axios
            .post("libs/check_avalible.php", {
              phone: this.form.phone.data,
              action: "checkPhone",
              token: this.$store.state.tempToken,
            })
            .then(response => {
              this.isLoading = false;
              console.log("checkPhone:", response.data);
              if (response.data.success) {
                resolve(true);
              } else {
                this.form.phone.message = response.data.message;
                this.form.phone.status = "is-danger";
                resolve(false);
              }
            })
            .catch(error => {
              this.isLoading = false;
              console.log(error);
              reject(false);
            });
        } else {
          this.form.status = "is-danger";
          this.form.result = true;
          this.form.message = "Ooops! Something went wrong. Please try again.";
          resolve(false);
        }
      });
    },

    doRegister() {
      //evt.preventDefault();
      //this.$store.commit("DO_LOGIN", true);
      //this.$store.commit("SHOW_MODAL", false);
      //const whitespaceRemoved = str.replace(/\s/g, '');
      if (this.formCheck("name") & this.emailCheck() & this.formCheck("phone")){
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post("users/register.php", {
            action: "register",
            name: this.form.name.data,
            email: this.form.email.data,
            phoneCode: this.form.phone.code,
            phone: this.form.phone.data,
            token: this.$store.state.tempToken,
          })
          .then(response => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.form.visible = false;
              //this.$store.commit("DO_LOGIN", true);
              //this.$store.dispatch("SET_USERDATA", response.data);
              //this.$router.push({ path: "/category/all" });
              //this.closeModal();
            } else {
              this.form.status = "is-danger";
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              }              
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    resetForm() {
      this.form.name.data = "";
      this.form.name.status = "";
      this.form.name.message = "";

      this.form.email.data = "";
      this.form.email.status = "";
      this.form.email.message = "";

      this.form.phone.data = "";
      this.form.phone.status = "";
      this.form.phone.message = "";

      this.form.phone.code = "+373";


      // this.form.pass.data = "";
      // this.form.pass.status = "";
      // this.form.pass.message = "";
      this.form.message = "";
      this.form.result = false;
      this.form.status = "";
      this.isLoading = false;
      this.form.visible = true;
    },
    closeModal() {
      this.resetForm();
      this.$store.commit("modals/hideModal", "register");
    },
    openLogin() {
      this.$store.commit("modals/showModal", "login");
      this.closeModal();
    }
  },
  computed: {
    isActive: {
      get: function () {
        return this.$store.state.modals.register;
      },
      // setter
      set: function () {
        this.$store.commit("modals/hideModal", "register");
        return false;
      }
    }
  },
  watch: {
    isActive: function () {
      this.resetForm();
    }
  }
};
</script>
<style scoped>
.modal-card-foot {
  justify-content: space-between;
  /* flex-end; */
}
</style>
